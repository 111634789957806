<template>
  <div class="home">
    <v-row class="">
      <v-col
        class=""
        cols="12" 
        sm="8" offset-sm="2"
        md="6" offset-md="3"
        xl="6" offset-xl="3"
      >
        <router-view :key="$route.path" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'paymentRoot',
  }
</script>

<style scoped>

</style>
